import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@mantine/core';
import style from './style';
import createMarkup from '../../../js/utils/formatters/html-to-text-formatter';
import devices from '../../../js/styles/Devices';
import useDevice from '../../../js/utils/use-device';
import LinkBlock from '../../blocks/LinkBlock';
import NewFormBlock from '../../blocks/NewFormBlock/SE/advisa/NewFormBlock';

const Section = styled.section`
  height: 90vh;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-image: url(${({ background }) => background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${devices.downFromTablet} {
    background-image: url(${({ mobileBackground }) => mobileBackground});
    display: inherit;
  }
`;

const ContentWrapper = styled.div`
  max-width: 50%;
  margin: auto 10%;

  @media ${devices.downFromDesktop} {
    max-width: 60%;
  }

  @media ${devices.downFromTablet} {
    max-width: 100%;
    margin: 0;
    padding: 2.5rem 1.25rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${style.contentWrapperBgOverlay};
  }
`;

const Title = styled.h1`
  @media ${devices.downFromTablet} {
    color: ${style.mobileTitleColor};
  }
`;

const Text = styled.p`
  text-decoration: none;
`;

const ButtonsWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 2.5rem;

  @media ${devices.downFromTablet} {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const ButtonLink = styled(LinkBlock)`
  cursor: pointer;
  text-decoration: none;

  span {
    color: ${style.buttonTextColor};
  }
`;

const TrustBox = styled.aside`
  position: absolute;
  bottom: 5%;
  right: 2.5%;
  max-width: 28rem;
  max-height: 7rem;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  border-radius: 8px;
  background: ${style.trustBoxBg};

  @media ${devices.downFromTablet} {
    display: none;
  }
`;

const TrustBoxImage = styled.img`
  width: 55px;
  height: 55px;
`;

const TrustBoxText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;

  p {
    margin: 0 !important;
  }
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.75);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
`;

const IndexSection = React.memo(({ acf }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { isTabletAndUp } = useDevice();

  if (!acf) return null;

  const {
    backgroundImage,
    mobileBackgroundImage,
    indexTitle,
    indexText,
    buttons,
    trustBoxText,
    trustBoxImage,
  } = acf;

  const backgroundImageUrl = backgroundImage?.image?.node?.sourceUrl
    || backgroundImage?.svgImage?.node?.sourceUrl;

  const mobileBackgroundImageUrl = mobileBackgroundImage?.image?.node?.sourceUrl
    || mobileBackgroundImage?.svgImage?.node?.sourceUrl;

  const trustBoxImageUrl = trustBoxImage?.image?.node?.sourceUrl
    || trustBoxImage?.svgImage?.node?.sourceUrl;

  const handleButtonClick = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  return (
    <>
      <Section
        background={backgroundImageUrl}
        mobileBackground={mobileBackgroundImageUrl}
      >
        <ContentWrapper>
          <Title dangerouslySetInnerHTML={createMarkup(indexTitle)} />
          {isTabletAndUp && <Text dangerouslySetInnerHTML={createMarkup(indexText)} />}
          <ButtonsWrapper>
            <Button type="button" onClick={handleButtonClick}>
              <span>Open Form</span>
            </Button>
            {buttons.map((button, i) => (
              <Button
                key={`button-${i}-${button.id || i}`}
                variant={i === 0 ? 'filled' : 'outline'}
              >
                <ButtonLink
                  {...button}
                />
              </Button>
            ))}
          </ButtonsWrapper>
        </ContentWrapper>
        <TrustBox>
          {trustBoxImageUrl && <TrustBoxImage src={trustBoxImageUrl} alt="TrustBox" />}
          <TrustBoxText dangerouslySetInnerHTML={createMarkup(trustBoxText)} />
        </TrustBox>
      </Section>
      {isFormOpen && (
        <Overlay>
          <CloseButton onClick={closeForm}>&times;</CloseButton>
          <NewFormBlock />
        </Overlay>
      )}
    </>
  );
});

IndexSection.propTypes = {
  acf: PropTypes.shape({
    backgroundImage: PropTypes.shape({
      image: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
      svgImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
    }),
    mobileBackgroundImage: PropTypes.shape({
      image: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
      svgImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
    }),
    indexTitle: PropTypes.string,
    indexText: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    })),
    trustBoxText: PropTypes.string,
    trustBoxImage: PropTypes.shape({
      image: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
      svgImage: PropTypes.shape({
        node: PropTypes.shape({
          sourceUrl: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

export default IndexSection;
